export const DAY_TRIPS_COLLECTION_MBS = [
  'www.stonehenge-london-tours.com', // 212
  'www.mount-fuji.com', // 1819
  'bluemountains.tickets-sydney.com', // 1682
  'www.milford-sound-cruises.com.queenstown-to-milford-sound-tours', // 5177
  'www.pompeii-tickets.com.naples-to-pompeii-day-trip', // 3664
  'greatoceanroad.tickets-melbourn.com',
  'batucaves.malaysia-tickets.com', // 3813
  'www.phiphi-islands-tours.com', // 2405
];
